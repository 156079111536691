<template>
  <page :styleWrap='{background: "#fff"}' :state='pageState'>
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>

      <div class="closeGroup" v-if='hasClose'>
        <div v-for='(item, index) in groupList' :key='index'>
          <div class="closeGroupTitle">
            {{item.company.companyName}}
          </div>
          <group-item :disable='false' :titleStyle="{color: '#9B9B9B'}" v-for='(ii, key) in item.groupList' :key='key' :info='ii'></group-item>
        </div>
      </div>
      <div v-else>
        <img class="emptyImg margin_auto" src="../../assets/groupEmpty.png" alt="">
        <p class="tip">没有关闭的群~</p>
        <button @click="$router.back()" class="margin_auto theme_bg btn btn_radius" style="width: 80%; margin-top: 50px">返回</button>
      </div>
    </div>
  </page>
</template>

<script>
import groupItem from './groupItem.vue'
import groupApi from '@/api/group'

export default {
  components: {
    'group-item': groupItem
  },
  data: ()=> ({
    groupList: [],
    pageState: 'loading',
  }),
  mounted() {
    this.getData()
  },
  computed: {
    hasClose() {
      let b = false
      if (!this.groupList.length) return false;
      this.groupList.forEach(item => {
        if (item.groupList.length) {
          b = true
        }
      });
      return b
    }
  },
  methods: {
    getData() {
      groupApi.groupList({
        groupStatus: 2,
        companyId: JSON.parse (window.localStorage.getItem ('companyInfo')).companyId
      }).then(res => {
        if (res.data) {
          this.groupList = res.data.companyGroupList
          this.pageState = 'success'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.closeGroup {
  position: relative;
  padding: 0 15px;
  .closeGroupTitle {
    font-size: 15px;
    color: #434343;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 20px;
  }
}
.emptyImg {
  width: 150px;
  height: 120px;
  margin-top: 100px;
}
.tip {
  color: #C1CAD4;
  font-size: 14px;
  text-align: center;
  margin-top: 17px;
  margin-bottom: 150px;
}
</style>